@import url(https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css);
.innerHeader {
    width: var(--mainWidth);
    height: var(--headerHight);
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--border);
    padding-inline: 20px;
    background-color: rgba(255,255,255,0.9);
    position: fixed;
    top: var(--headerHight);
    z-index: 100;
}
.innerHeader .title {
    line-height: 1;
}
.btnWrap {
    display: flex;
    align-items: center;
    height: 100%;
}
.btnWrap > * {
    height: -webkit-max-content;
    height: max-content;
}
.btnWrap .MuiSelect-select {
    padding: 8px 16px !important;
}
@media (max-width: 600px) {
    .innerHeader {
        height: calc(var(--headerHight) + 8px);
    }
    .innerHeader .title {
        font-size: 18px;
    }
    .btnWrap .MuiSelect-select {
        padding: 4px 8px !important;
        font-size: 12px !important;
    }
    .MuiPaper-root .MuiMenuItem-root {
        font-size: 12px !important;
    }
}
header {
    width: var(--mainWidth);
    height: var(--headerHight);
    border-bottom: var(--border);
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: rgba(255,255,255,0.9);
}
.homeLink {
    display: none;
}
.logo {
    height: 90%;
    margin-left: -10px;
}
.headerRight {
    height: inherit;
    margin-left: auto;
    /* border-left: var(--border); */
}
.settingMenu, .settingBtn {
    height: inherit;
    min-width: var(--headerHight) !important;
}
.settingMenu {
    border-left: var(--border);
}
@media (max-width: 600px) {
    .homeLink {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .headerRight {
        border-left: none;
    }
    .settingMenu {
        display: none;
    }
}
.sideNav {
    width: var(--sideNavWidth) !important;
    height: 100%; 
    border-right: var(--border);
}
.SideNavTop {
    height: var(--headerHight);
    align-items: center;
    border-bottom: var(--border);
    overflow: hidden;
    padding-inline: 10px;
}
.SideNavTop :is(a, .logo) {
    height: 100%;
}
.SideMenu {
    margin-top: 16px;
    padding-inline: 10px;
}
.SideMenu a {
    width: 100%;
    display: block;
    line-height: 1;
    border-radius: 4px;
    margin-bottom: 4px;
    padding-block: 12px;
    padding-left: 10px;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.SideMenu a:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
.SideMenu a.active {
    color: #1976d2;
    background-color: #f0f7ff;
    font-weight: 600;
}

@media (max-width: 600px) {
    .SideMenu {
        width: 70vw;
    }
}
#root {
    width: 100vw;
    height: 100dvh;
}
:root {
    --headerHight: 60px;
    --sideNavWidth: 240px;
    --mainWidth: calc(100vw - var(--sideNavWidth));
    --border: 1px solid #ddd;
}
@media (max-width: 600px) {
    :root {
        --mainWidth: 100%;
        --headerHight: 44px;
    }
    body {
        font-size: 14px;
    }
}

.layout {
    width: 100%;
    height: 100%;
}
.App {
    text-align: center;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Pretendard Variable';
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}
body::-webkit-scrollbar {
  display: none;
}
main {
  min-height: 100vh;
}
a {
  color: inherit;
  text-decoration: none;
  color: #333;
}
li {
  list-style: none;
}

.row {
  display: flex;
  flex-direction: row;
}
.center {
  justify-content: center;
  align-items: center;
}
.App {
  text-align: center;
}
.audioContainer {
  display: flex;
}
.audioContainer > div:nth-child(1) {
  text-align: center;
}
