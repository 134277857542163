.innerHeader {
    width: var(--mainWidth);
    height: var(--headerHight);
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--border);
    padding-inline: 20px;
    background-color: rgba(255,255,255,0.9);
    position: fixed;
    top: var(--headerHight);
    z-index: 100;
}
.innerHeader .title {
    line-height: 1;
}
.btnWrap {
    display: flex;
    align-items: center;
    height: 100%;
}
.btnWrap > * {
    height: max-content;
}
.btnWrap .MuiSelect-select {
    padding: 8px 16px !important;
}
@media (max-width: 600px) {
    .innerHeader {
        height: calc(var(--headerHight) + 8px);
    }
    .innerHeader .title {
        font-size: 18px;
    }
    .btnWrap .MuiSelect-select {
        padding: 4px 8px !important;
        font-size: 12px !important;
    }
    .MuiPaper-root .MuiMenuItem-root {
        font-size: 12px !important;
    }
}