#root {
    width: 100vw;
    height: 100dvh;
}
:root {
    --headerHight: 60px;
    --sideNavWidth: 240px;
    --mainWidth: calc(100vw - var(--sideNavWidth));
    --border: 1px solid #ddd;
}
@media (max-width: 600px) {
    :root {
        --mainWidth: 100%;
        --headerHight: 44px;
    }
    body {
        font-size: 14px;
    }
}

.layout {
    width: 100%;
    height: 100%;
}