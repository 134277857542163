@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Pretendard Variable';
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}
body::-webkit-scrollbar {
  display: none;
}
main {
  min-height: 100vh;
}
a {
  color: inherit;
  text-decoration: none;
  color: #333;
}
li {
  list-style: none;
}

.row {
  display: flex;
  flex-direction: row;
}
.center {
  justify-content: center;
  align-items: center;
}
.App {
  text-align: center;
}
.audioContainer {
  display: flex;
}
.audioContainer > div:nth-child(1) {
  text-align: center;
}