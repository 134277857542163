header {
    width: var(--mainWidth);
    height: var(--headerHight);
    border-bottom: var(--border);
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 100;
    background-color: rgba(255,255,255,0.9);
}
.homeLink {
    display: none;
}
.logo {
    height: 90%;
    margin-left: -10px;
}
.headerRight {
    height: inherit;
    margin-left: auto;
    /* border-left: var(--border); */
}
.settingMenu, .settingBtn {
    height: inherit;
    min-width: var(--headerHight) !important;
}
.settingMenu {
    border-left: var(--border);
}
@media (max-width: 600px) {
    .homeLink {
        height: 100%;
        display: flex;
        align-items: center;
    }
    .headerRight {
        border-left: none;
    }
    .settingMenu {
        display: none;
    }
}